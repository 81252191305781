@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500&display=swap");
.container {
	font-family: Montserrat, Arial, Helvetica, sans-serif;
	padding: 20px;
	width: 500px;
	min-height: 450px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border: 1px solid #ccc;
	border-radius: 10px;
	background: white;
	-webkit-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
	box-shadow: 2px 1px 21px -9px rgba(0, 0, 0, 0.38);
}
h1 {
	padding: 15px;
	text-align: center;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	width: 100%;
	padding: 15px;
	margin: 5px 0 22px 0;
	display: inline-block;
	border: none;
	background: #f7f7f7;
	font-family: Montserrat, Arial, Helvetica, sans-serif;
}

a {
	text-decoration: none;
	transition: all 0.3s ease;
}

.sign-up-button {
	background-color: #0eb7f4;
	color: white;
	padding: 10px 10px;
	margin: 8px 0;
	border: none;
	cursor: pointer;
	width: 100%;
	opacity: 0.9;
	font-size: 16px;
	font-family: Montserrat, Arial, Helvetica, sans-serif;
	border-radius: 10px;
}

@media screen and (max-width: 300px) {
	.signupbtn {
		width: 100%;
	}
}
