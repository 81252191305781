* {
	border: 0;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	height: 100vh;
	font-family: "Roboto", sans-serif;
	background: black;
}

/* button {
	background-color: #0eb7f4;
	color: white;
	padding: 14px 20px;
	margin: 8px 0;
	border: none;
	cursor: pointer;
	width: 100%;
	opacity: 0.9;
	font-size: 16px;
	font-family: Montserrat, Arial, Helvetica, sans-serif;
	border-radius: 10px;
} */

button:hover {
	opacity: 1;
}
