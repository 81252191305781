.pagination-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

ul {
	display: flex;
}
