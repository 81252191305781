.movies-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	max-width: 100%;
	padding: 15px;
	flex-wrap: wrap;
}
@media (max-width: 800px) {
	.movies-container {
		flex-direction: column;
		width: 400px;
	}
}
